// **  Initial State
const initialState = {
    change: false,
    accountType: localStorage.getItem('identity') || 3,
    thresholds: {},
    settings: {},
    local: {},
    domain: {}
}

const identityUsersReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ACCOUNT_TYPE': {
            localStorage.setItem('identity', action.val)

            return { ...state, accountType: action.val }
        }

        case 'IDENTITY':
            return { ...state, ...action.val }

        default:
            return state
    }
}

export default identityUsersReducer
