// ** Initial State
const initialState = {
  limitsExceeded: false,
  assetsInfo: {
    total: 0,
    allowed_assets: 0,
    agents: 0,
    agentless: 0,
    online: 0,
    recently_added: 0,
    recently_days: 1,
    security_rating: 0
  },
  assetsFilter: {
    change: false,
    type: [],
    windows_type: '',
    cloud: '',
    agent_version: [],
    asset_status: '',
    agent_status: '',
    scan_status: '',
    security_rating: []
  },
  malwareProtection: [],
  agentVersions: {
    data: [],
    change: false,
    status: ''
  },
  assetType: [
    { category: 1, value: 'windows', label: 'Windows' },
    { category: 1, value: 'Web Server', label: 'Web Server' },
    { category: 1, value: 'linux', label: 'Linux' },
    { category: 1, value: 'network', label: 'Network' },
    { category: 1, value: 'database', label: 'Database' },
    { category: 2, value: 'Printers', label: 'Printers' },
    { category: 2, value: 'Productivity Software', label: 'Productivity Software' }
  ]
}

const assetsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LIMITS_EXCEEDED':
      return { ...state, limitsExceeded: action.val }

    case 'ASSETS_INFO':
      return { ...state, assetsInfo: action.val }

    case 'ASSETS_FILTER': {
      return {
        ...state,
        assetsFilter: {
          ...state.assetsFilter,
          ...action.val
        }
      }
    }
    case 'ASSET_FILTER_NEEDED': {
      return {
        ...state,
        malwareProtection: action.val?.malware_protection,
        agentVersions: { ...state.agentVersions, data: action.val?.agent_versions }
      }
    }
    case 'ASSET_RECENTLY_DAYS': {
      return {
        ...state,
        assetsInfo: { ...state.assetsInfo, recently_days: action.val }
      }
    }
    case 'AGENT_VERSIONS': {
      let assetType = state.assetType

      if (['filter', 'deleted', 'unlicensed', 'uninstalled', 'stopped'].includes(action.val.status)) {
        assetType = state.assetType.filter(type => type.category !== 2)
      }
      return { ...state, agentVersions: action.val, assetType }
    }

    default:
      return state
  }
}

export default assetsReducer