const initialState = {
    currentStep: 1,
    finish: false,
    id: null,
    info: {
        policyName: '',
        policyDescription: ''
    },
    type: {
        whitelistedApplications: true,
        blacklistedApplications: false,
        mandatoryApplications: false,
        allowOnlyApplications: false
    },
    assignment: {
        applyThisPolicyForAllHosts: true,
        // SelectedGroups: []
        SelectedGroups: []
    },
    applications: {
        applicationsType: true,
        applicationsLeftSide: [],
        applicationsRightSide: [],
        vendorsLeftSide: [],
        vendorsRightSide: []
    }
}


const policyWizaredReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'POLICY_APP': {
            return {
                ...state,
                ...action.value, // Merging action.value into the state
                currentStep: 1,
                finish: false
            }
        }
        case 'PREVIOUS_STEP_POLICY_WIZARED': {
            let tempStep = state.currentStep
            if (tempStep > 1) tempStep -= 1
            return { ...state, currentStep: tempStep }
        }

        case 'NEXT_STEP_POLICY_WIZARED': {
            let tempStep = state.currentStep
            if (tempStep < 5) tempStep += 1
            return { ...state, currentStep: tempStep }
        }

        case 'POLICY_STEP': {
            return { ...state, currentStep: action.value }
        }

        case 'POLICY_DATA': {
            let tempStep = state.currentStep
            if (action.value?.stepDirection) {
                if (action.value.stepDirection === 'Previous' && state.currentStep > 1) {
                    tempStep -= 1
                }

                if (action.value.stepDirection === 'Next' && state.currentStep < 5) {
                    tempStep += 1
                }
            }
            return { ...state, currentStep: tempStep, [action.value.key]: action.value.data }

            // state[action.payload.key] = action.payload.data;
        }
        case 'FETCH_POLICY_APPS': {
            return { ...state, applications: action.value }

        }
        case 'FINISH_POLICY_WIZARED': {
            return { ...state, finish: action.value }
        }
        case 'INITIAL_POLICY_WIZARED': {
            return initialState
        }
        default:
            return state
    }
}
export default policyWizaredReducer