import secureLocalStorage from 'react-secure-storage'

// ** Initial State
const data = secureLocalStorage.getItem('groups')
const initialState = {
  groups: data ? JSON.parse(data) : []
}

const groupsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GROUPS':
      secureLocalStorage.setItem('groups', JSON.stringify(action.data))
      return { ...state, groups: action.data }

    default:
      return state
  }
}

export default groupsReducer
