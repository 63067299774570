// ** Initial State
const initialState = {
    collapseCard: true,
    devices: false
}

const devicesReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'COLLAPSECARD':
            return { ...state, collapseCard: action.val }

        case 'DEVICES':
            return { ...state, devices: action.val }

        default:
            return state
    }
}

export default devicesReducer