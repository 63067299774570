import { Ability } from '@casl/ability'
import { initialAbility } from './initialAbility'

import secureLocalStorage from 'react-secure-storage'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
const data = secureLocalStorage.getItem('userData')
const ability = data ? JSON.parse(data).ability : null
const existingAbility = ability

export default new Ability(existingAbility || initialAbility)
