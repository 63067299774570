// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import reports from './reports'
import assetPage from './asset-page'
import assets from './assets'
import devices from './devices'
import groups from './groups'
import version from './version'
import rateCompliance from './rate-compliance'
import cyscan from './cyscan'
import identity from './identity'
import dnsSecurity from './dns-security'
import policyWizared from './policy-wizared'
import chat from '@views/ticketing-center/chat/store/reducer'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  reports,
  assetPage,
  assets,
  devices,
  groups,
  version,
  rateCompliance,
  cyscan,
  identity,
  dnsSecurity,
  policyWizared,
  chat
})

export default rootReducer
