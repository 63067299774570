// ** Initial State
const initialState = {
    addDomain: false,
    processing: false
}

const DNSSecurity = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_DOMAIN':
            return { ...state, addDomain: action.val }

        case 'DNS_PROCESSING':
            return { ...state, processing: action.val }

        default:
            return state
    }
}

export default DNSSecurity