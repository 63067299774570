import secureLocalStorage from 'react-secure-storage'

// **  Initial State
const data = secureLocalStorage.getItem('userData')
const initialState = {
  userData: data ? JSON.parse(data) : {}
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'USER_DATA':
      return { ...state, userData: action.data }

    case 'LOGIN':
      return { ...state, userData: action.data }

    case 'LOGOUT':
      return { ...state, userData: {} }

    default:
      return state
  }
}

export default authReducer
