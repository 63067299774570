// ** Initial State
const initialState = {
    pathname: '',
    assets: {
        hostname: '',
        os: ''
    },
    group: {},
    subdomain: '',
    dataBreach: false,
    showPassword: false,
    hostname: '',
    windowsUsersFilter: {
        change: false,
        accountType: 0,
        status: '',
        login: '',
        adminType: []
    },
    adFilter: {}
}

const navbarReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'PATHNAME':
            return { ...state, pathname: action.val }

        case 'ASSETS':
            return { ...state, assets: action.val }

        case 'GROUP':
            return { ...state, group: action.val }

        case 'DARKREALEM':
            return { ...state, ...action.val }

        case 'SHOWPASSWORD':
            return { ...state, showPassword: action.val }

        case 'HOSTNAME':
            return { ...state, hostname: action.val }

        case 'WINDOWS_USERS_FILTER': {
            return {
                ...state,
                windowsUsersFilter: {
                    ...state.windowsUsersFilter,
                    ...action.val
                }
            }
        }

        case 'AD_FILTER': {
            return {
                ...state,
                adFilter: {
                    ...state.adFilter,
                    ...action.val
                }
            }
        }

        default:
            return state
    }
}

export default navbarReducer
