const initialState = {
  tickets: [],
  usersList: [],
  userProfile: {},
  selectedTicket: {}
}

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_USER_PROFILE':
      return { ...state, userProfile: action.userProfile }
    case 'GET_CHAT':
      let selectedTicketState = state.selectedTicket
      if (Object.keys(selectedTicketState).length > 0) {
        selectedTicketState = action.data.find(item => +item.id === +selectedTicketState.id)
      }
      return { ...state, tickets: action.data, userProfile: action.userProfile, selectedTicket: selectedTicketState }
    case 'SET_USERS_LIST':
      return { ...state, usersList: action.data }
    case 'SELECT_CHAT':
      const selectedTicketAction = action.data
      const prevTickets = state.tickets
      const newTickets = prevTickets.map(ticket => {
        if (+ticket.id === selectedTicketAction.id) {
          ticket.lastMessage = selectedTicketAction.messages[selectedTicketAction.messages.length - 1]
          ticket.status = selectedTicketAction.status
          ticket.ticket_status = selectedTicketAction.ticket_status
          return ticket
        }
        return ticket
      })
      return { ...state, tickets: newTickets, selectedTicket: selectedTicketAction }
    case 'REFRESH_CHAT':
      return { ...state, selectedTicket: {} }
    case 'SELECT_Ticket':
      return { ...state, selectedTicket: state.tickets.find(item => +item.id === +action.id) }
    case 'SEND_MSG':
      // ** Add new msg to chat
      let newMsg = action.data
      newMsg = {
        ...newMsg,
        message: newMsg.Comments,
        senderId: newMsg.UserID,
        time: newMsg.created_at
      }
      const tickets = state.tickets
      const newTicketsArr = tickets.map(ticket => {
        if (+ticket.id === +newMsg.TicketID) {
          ticket.lastMessage = newMsg
          return ticket
        }
        return ticket
      })
      const newMessages = state.selectedTicket.messages
      newMessages.push(newMsg)
      return { ...state, tickets: newTicketsArr, selectedTicket: { ...state.selectedTicket, messages: newMessages } }
    case 'RESET_CHAT':
      return initialState
    default:
      return state
  }
}

export default chatReducer
