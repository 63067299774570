// ** Initial State
const initialState = {
    customerPublicIPs: [],
    selectedPublicIP: 'all',
    networks: {},
    public_ips: {}
}

const cyscanReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CYSCAN':
            return { ...state, ...action.val }

        case 'PUBLIC_IPS':
            return { ...state, public_ips: action.val }

        case 'ADD_NETWORK': {
            const publicIP = action.val?.publicIP
            const private_ip = action.val?.private_ip

            let network = state.networks?.[publicIP]
            const exist = network?.private_ips
                .filter(item => {
                    if (
                        item.ip_address === private_ip.ip_address &&
                        item?.cyscan_agent?.value === private_ip?.cyscan_agent?.value
                    ) {
                        return item
                    }
                })

            if (exist === undefined || exist?.length === 0) {
                network = {
                    ...network,
                    agents: action.val?.agents,
                    settings: network?.settings ? network.settings : {
                        ping_scan: 'ON',
                        port_scan: 'OFF',
                        port_range: 'top100',
                        range_min: 1,
                        range_max: 65535
                    },
                    private_ips: [...network?.private_ips || [], private_ip]
                }

                return {
                    ...state,
                    networks: {
                        ...state.networks,
                        [publicIP]: network
                    },
                    customerPublicIPs: [
                        ...state.customerPublicIPs,
                        { value: publicIP, label: publicIP }
                    ].filter((item, index, self) => {
                        return index === self.findIndex(element => element?.value === item?.value)
                    })
                }
            }

            return state
        }

        case 'NETWORK_SETTINGS': {
            const publicIP = action.val?.publicIP

            return {
                ...state,
                networks: {
                    ...state.networks,
                    [publicIP]: {
                        ...state.networks?.[publicIP],
                        settings: {
                            ...state.networks?.[publicIP].settings,
                            ...action.val?.settings
                        }
                    }
                }
            }
        }

        case 'NETWORK_ITEM': {
            const publicIP = action.val?.publicIP

            const private_ips = [...state.networks?.[publicIP]?.private_ips]
            private_ips[action.val?.index] = action.val?.private_ip

            return {
                ...state,
                networks: {
                    ...state.networks,
                    [publicIP]: {
                        ...state.networks?.[publicIP],
                        private_ips
                    }
                }
            }
        }

        case 'DELETE_NETWORK_ITEM': {
            const publicIP = action.val?.publicIP
            const index = action.val?.index

            const privateIPs = state?.networks?.[publicIP]?.private_ips
                ?.filter((privateIP, arrayIndex) => {
                    if (arrayIndex !== index) {
                        return privateIP
                    }
                })

            let networks = state?.networks
            let customerPublicIPs = state?.customerPublicIPs

            if (privateIPs?.length > 0) {
                networks = {
                    ...networks,
                    [publicIP]: {
                        ...networks?.[publicIP],
                        private_ips: privateIPs
                    }
                }
            } else {
                customerPublicIPs = customerPublicIPs?.filter(customerPublicIP => {
                    if (customerPublicIP.value !== publicIP) {
                        return customerPublicIP
                    }
                })

                delete networks?.[publicIP]
            }

            return {
                ...state,
                networks,
                customerPublicIPs
            }
        }

        default:
            return state
    }
}

export default cyscanReducer