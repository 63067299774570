import secureLocalStorage from 'react-secure-storage'

// **  Initial State
const initialState = {
  version: secureLocalStorage.getItem('version')
}

const versionReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'VERSION':
      return { ...state, version: action.version }

    default:
      return state
  }
}

export default versionReducer
