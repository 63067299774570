// ** Initial State
const initialState = {
    assetInfo: {},
    monitoring: {},
    collapseCard: true,
    changeTab: false,
    controlsActions: {
        applicability: false,
        execlude: false,
        remediation: false,
        notes: false,
        attachments: false,
        ticket: false
    }
}

const assetPageReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ASSET_DATA':
            return { ...state, ...action.data }

        case 'ASSET_INFO': 
            return {
                ...state,
                assetInfo: {
                    ...state.assetInfo,
                    ...action.data
                }
            }

        case 'COLLAPSE_CARD': {
            return { ...state, collapseCard: action.val }
        }

        case 'CHANGE_TAP': {
            return { ...state, collapseCard: false, changeTab: action.val }
        }

        case 'CONTROLS_ACTIONS':
            return { ...state, controlsActions: action.val }

        default:
            return state
    }
}

export default assetPageReducer