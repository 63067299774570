// **  Initial State
const initialState = {
  rateCompliance: localStorage.getItem('rateCompliance') || 70
}

const rateComplianceReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'RATE_COMPLIANCE': {
      localStorage.setItem('rateCompliance', action.val)

      return { ...state, rateCompliance: action.val }
    }

    default:
      return state
  }
}

export default rateComplianceReducer
